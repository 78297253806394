.section {
  padding-top: 30px;
  padding-bottom: 30px;

  background-color: #000;
}

.artistBox {
  background: linear-gradient(305.19deg, rgba(74, 84, 95, 0.4) 13.38%, rgba(41, 43, 45, 0.088) 43.65%, rgba(74, 84, 95, 0.4) 66.03%, rgba(41, 43, 45, 0.088) 97.61%);
  border: 2px solid rgba(210, 220, 233, 0.1);
  backdrop-filter: blur(30px);
  border-radius: 25px;

  padding: 15px;

  display: flex;
  flex-direction: column;
  gap: 25px;
}

.artistInfo {
  display: flex;
  flex-direction: column;
  gap: 15px;

  color: white;
}

.balanceRow {
  margin-top: 15px;
  width: 100%;
  color: #FF9500;
  background: rgba(255, 149, 0, 0.15);
  border-radius: 100px;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  padding: 8px 32px;
  font-weight: 600;
}

.successMessage {
  margin-top: 15px;
  width: 100%;
  color: #00A87E;
  background: rgba(0, 168, 126, 0.15);
  border-radius: 100px;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  padding: 8px 32px;
  font-weight: 600;
}

.soldAnnounce {
  position: absolute;
  top: 0;

  z-index: 3;

  height: 100%;
  width: 100%;
  border-radius: 25px;
  background-color: rgba(29, 30, 37, 0.7);

  color: #fff;

  font-weight: var(--bold);
  font-size: 90px;
  line-height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.soldAnnounce span {
  opacity: 0.5;
}

.nftBox {
  position: relative;
}

.nftBox img {
  object-fit: cover;
  max-height: 800px;
}

.nftBox video {
  width: 100%;
  height: 100%;
}

.title {
  font-weight: var(--bold);
  font-size: 26px;
  line-height: 26px;
}

.description {
  font-size: 18px;
  line-height: 140%;
}

.cta {
  margin-top: 15px;
  display: flex;
  gap: 25px;
}

.cta button {
  cursor: pointer;
  color: white;
  background: linear-gradient(278.12deg, #4B11AA 4.07%, #9447FF 97.88%);
  border-radius: 14px;

  font-size: 20px;
  line-height: 24px;
  font-weight: var(--bold);

  min-width: 220px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.cta * {
  cursor: pointer;
}

.nftList {
  display: flex;
  margin-top: 40px;
  gap: 30px;
  flex-wrap: wrap;
}

.nftClass {
  color: white;

  padding: 10px;
  width: 100%;
  border-radius: 15px;
  background: linear-gradient(305.19deg, rgba(74, 84, 95, 0.4) 13.38%, rgba(41, 43, 45, 0.088) 43.65%, rgba(74, 84, 95, 0.4) 66.03%, rgba(41, 43, 45, 0.088) 97.61%);
  backdrop-filter: blur(30px);
  border-radius: 25px;
}

.nftClass img {
  width: 100%;
  border-radius: 25px;
}

.nftClass video {
  width: 100%;
  border-radius: 25px;
}

.nftInfo {
  padding: 30px;

  border-radius: 0px 0px 20px 20px;
}

.nftAbout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.nftArtist {
  flex-direction: column;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 22px;
  line-height: 22px;
}


.nftPrices {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.eqxPrice {
  font-size: 18px;
  line-height: 18px;
  text-align: right;
}

.dollarPrice {
  font-size: 14px;
  line-height: 14px;
  color: #B4C4DA;
}

.buttonsRow {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
  gap: 20px;
}

.artistName {
  font-size: 16px;
  line-height: 16px;
  font-weight: var(--bold);
}

.nftDescription {
  text-align: center;
  font-size: 18px;
  line-height: 18px;
}

.buttonsRow .buyEqx {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--bold);
}


/* safari media query */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .buttonsRow .buyEqx {
      margin-top: 20px;
    }
  }
}

.buttonsRow .buyEqx img {
  width: 20px;
  height: 20px;
}


.buttonsRow .purchase {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 250px;
  height: 50px;
  background-color: var(--primaryBlue);
  border-radius: 14px;
  color: white;
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--bold);
}

.purchase:disabled {
  background-color: rgba(210, 220, 233, 0.6) !important;
  color: #99A9BD !important;
  cursor: not-allowed;
}

.purchase:disabled img {
  opacity: 0.2;
}

.buttonsRow .purchase img {
  width: 20px;
  height: 20px;
}

.sold {
  background-color: rgba(210, 220, 233, 0.6) !important;
  color: #99A9BD !important;
}

.artistImage {
  max-width: 400px;
  border-radius: 15px;
}

@media(min-width: 768px) {
  .section {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .artistInfo {
    gap: 20px;
  }

  .buttonsRow .buyEqx {
    margin-top: 0;
  }

  .balanceRow {
    margin-top: 30px;
  }

  .artistBox {
    flex-direction: row;
    gap: 90px;
    padding: 30px;
    padding-right: 75px;
    border-radius: 40px;
  }

  .artistImage {
    width: 100%;
    height: 100%;
  }

  .cta {
    margin-top: 20px;
    gap: 35px;
  }

  .nftAbout {
    flex-direction: row;
    align-items: flex-start;
    gap: 100px;
  }

  .nftArtist {
    max-width: 50%;
    align-items: flex-start;
  }

  .nftPrices {
    align-items: flex-end;
  }

  .buttonsRow {
    flex-direction: row;
  }

  .nftClass {
    flex-basis: calc(50% - 15px);
    padding: 0;
  }

  .soldAnnounce {
    font-size: 140px;
    line-height: 98px;
  }

  .nftDescription {
    text-align: left;
    font-size: 22px;
    line-height: 22px;
  }


  .artistName {
    font-size: 18px;
    line-height: 18px;
  }


  .eqxPrice {
    font-size: 22px;
    line-height: 22px;
  }

  .dollarPrice {
    font-size: 18px;
    line-height: 18px;
  }


  .nftPrices {
    gap: 15px;
  }

}