.footerContainer {
  padding-top: 60px;
  padding-bottom: 130px;

  height: 600px;

  position: relative;
  z-index: 1;
}

.footerBg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footerContent {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  gap: 50px;
}

.socialMediaRow {
  display: flex;
  gap: 30px;
}

.visitWebsite {
  background-color: white;
  color: var(--primaryBlue);
  min-width: 220px;
  height: 50px;
  border-radius: 14px;
  font-size: 20px;
  line-height: 25px;
  font-weight: var(--bold);

  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.socialMediaRow img {
  cursor: pointer;
}

.copyRightTermsRow {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
}

.termsPrivacy {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.667173px;

  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}

.hoverEffect:hover {
  cursor: pointer;
  text-decoration: underline;
}

.producedBy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.667173px;
}


@media(min-width: 768px) {
  .footerContainer {
    padding-top: 100px;
  }


  .copyRightTermsRow {
    flex-direction: row;
    gap: 0px;
  }

  .termsPrivacy {
    padding: 0;
  }
}