@keyframes pulse {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(0.6);
  }

  75% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.walletConnect {
  min-width: 260px;
  height: 50px;
  background-color: white;
  border-radius: 14px;
  color: var(--primaryBlue);
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--bold);
}

.wrongBgColor {
  background-color: rgba(255, 149, 0, 0.1) !important;
  height: 100%;
  width: 100%;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wrongColor {
  color: #FF9500 !important;
  font-weight: var(--bold);
  font-size: 20px;
  line-height: 24px;
}

.wrongInfo {
  font-size: 12px;
  line-height: 14.4px;
  color: #1B3040;
  text-align: center;

  margin-bottom: 10px;
}

.connectedBox {
  position: relative;
  cursor: pointer;
  min-width: 260px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px;
  gap: 10px;
  padding-right: 10px;
}

.connectText {
  background: rgba(33, 171, 145, 0.1);
  border-radius: 12px;
  padding-left: 25px;

  height: 100%;
  width: 100%;
  display: flex;
  gap: 35px;
  justify-content: flex-start;
  align-items: center;
  color: #00A87E;

  font-weight: 600;
  font-size: 20px;
}

.menuBorder {
  border-radius: 12px 12px 0px 0px;
}

.pulseAnimation {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #21AB91;

  animation: pulse 1s infinite;
}

.expandAnimation {
  transform: rotate(180deg);
  transition: transform 0.5s !important;
}

.collapseAnimation {
  transform: rotate(0deg);
  transition: transform 0.5s !important;
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;

  width: 100%;
  height: 60px;
  background-color: white;
  color: blue;
  border-radius: 0px 0px 12px 12px;

  padding: 5px;
}

.disconnect {
  height: 50px;
  background: rgba(210, 220, 233, 0.3);
  border-radius: 12px;

  color: var(--deepGray);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
}