@import './assets/css/bootstrap.css';
@import './assets/css/utils.css';

:root {
  --bs-gutter-x: 0;
  --primaryBlue: #1975ff;
  --primaryGreen: #00a87e;
  --black: #000000;
  --white: #ffffff;
  --footerGray: #eff3f8;
  --grey: #b4c4da;
  --deepGray: #6c7d8b;
  --darkGray: #1b3040;
  --lightGray: #d2dce9;
  --footerBackground: #f0f5f9;
  --magenta: #9447ff;
  --lightBlue: #84b5ff;
  --gradientBlue: linear-gradient(278.12deg, #003dab 4.07%, #1975ff 97.88%);
  --metropolis: 'Metropolis';
  --borderColor: #d2dce9;
  --backgroundGray: #f6f9fc;
  --lightGrayZeroFour: #e8edf4;
  --bold: 600;
  --title: 20px;
}
