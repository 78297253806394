.containerFrame {
  padding: 150px 0px;
  padding-bottom: 70px;
  color: white;
  background-color: #000;
}

.title {
  font-weight: var(--bold);
  font-size: 32px;
  line-height: 32px;
}

.description {
  margin-top: 20px;
  font-size: 18px;
  line-height: 25px;
}

.iframeChangelly {
  margin-top: 30px;
  width: 100%;
  min-height: 420px;
  border: none;
  border-radius: 15px;
}

.changellyInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media(min-width: 768px) {
  .title {
    font-size: 60px;
    line-height: 60px;
  }

  .containerFrame {
    padding-bottom: 150px;
  }

  .description {
    font-size: 26px;
    line-height: 36px;
    width: 70%;
  }

  .iframeChangelly {
    margin-top: 0px;
    width: 555px;
  }
}