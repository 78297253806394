.border-radius--20px {
  border-radius: 20px;
}

.border-radius--40px {
  border-radius: 40px;
}

.height--100px {
  height: 100px !important;
}

.position--relative {
  position: relative;
}