.fullContainer {
  width: 100%;
  background-color: #000;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 50px;
}

.title {
  font-size: 28px;
  line-height: 28px;
  font-weight: var(--bold);
  letter-spacing: -1px;
}

.artistsList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: calc(100% - 20px);
  width: 100%;
  padding-left: 20px;
}

.artist {
  flex: 0 1 calc(20% - 25px);
}

.artist img {
  width: 62px;
  height: 100%;
  min-height: 85px;
  object-fit: cover;
  border-radius: 15px;
}

@media(min-width: 768px) {
  .artist img {
    width: 132px;
  }

  .fullContainer {
    gap: 50px;
    padding-bottom: 70px;
  }

  .title {
    font-size: 40px;
    line-height: 40px;
  }

  .artistsList {
    padding: 0;
    gap: 25px;
    width: 800px;
  }
}