.heroPage {
  height: 100vh;
  width: 100%;

  position: relative;
}

.heroContent {
  color: white;
  padding: 25px;

  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.heroImage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  height: 100%;
  width: 100%;
  object-fit: cover;
}

.logo {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.headerRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.eqiverseRow {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.eqiTitle {
  font-size: 50px;
  line-height: 50px;
  font-weight: var(--bold);
  color: white;
  letter-spacing: 10px;
}

.eqiDescription {
  font-size: 22px;
  line-height: 30px;
}

.buyCard {
  text-align: center;

  max-width: 630px;
  padding: 20px 30px;
  background: linear-gradient(305.19deg, #2D3339 13.38%, rgba(41, 43, 45, 0.22) 43.65%, #2D3339 66.03%, rgba(41, 43, 45, 0.22) 97.61%);
  border-radius: 25px;

  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: calc(100% - 20px);
}

.buyCard span {
  font-weight: 22px;
  line-height: 26px;
  color: white;
}

.buttonsCard {
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.buyButton {
  background-color: var(--primaryBlue);
  color: white;
  min-width: 320px;
  height: 60px;
  border-radius: 14px;
  font-size: 20px;
  line-height: 24px;
  font-weight: var(--bold);
}



@media(min-width: 768px) {
  .buyButton {
    min-width: 400px;
  }

  .heroContent {
    padding: 30px;
  }

  .logo {
    width: auto;
    height: 35px;
  }

  .headerRow {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .eqiverseRow {
    margin-top: 45px;
  }


  .eqiTitle {
    letter-spacing: 36px;
    font-size: 60px;
  }

  .eqiDescription {
    font-size: 26px;
  }

  .buttonsCard {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  .buyCard {
    gap: 30px;
    border-radius: 40px;
    padding: 40px;
    bottom: 90px;
  }
}

@media(min-width: 1600px) {
  .heroContent {
    padding: 45px;
  }

  .eqiTitle {
    letter-spacing: 36px;
    font-size: 90px;
  }

  .eqiverseRow {
    margin-top: 70px;
  }

}

@media(min-width: 2000px) {
  .heroContent {
    padding: 90px;
  }
}