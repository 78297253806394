.adContainer {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #000;
}

.adContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.storeButtons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.buttonLink {
  cursor: pointer;

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 14px;
  width: 220px;
  height: 50px;
  background-color: var(--primaryBlue);
  color: white;
}

.buttonLink img {
  width: 20px;
  height: 20px;
}

@media(min-width: 768px) {
  .adContainer {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}